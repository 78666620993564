"use client";

import Cookies from "js-cookie";
import { useState } from "react";
import {
  isBlocksCompanyStage,
  isBlocksProductHeroCan,
  isBlocksContactFormStage,
  isBlocksHighlightsStage,
  isBlocksIngredientsStage,
  isBlocksProductRail,
  isBlocksProductStage,
  isBlocksQaArticle,
  isBlocksSustainabilityQuiz,
} from "../../helpers/typeguards";
import { LegalDisclaimerChile } from "../legal-disclaimer-chile/legal-disclaimer-chile";
import { LegalDisclaimerProduct } from "../legal-disclaimer-product/legal-disclaimer-product";
import { LegalDisclaimer } from "../legal-disclaimer/legal-disclaimer";
import { useLocaleStringContext } from "../../helpers/LocaleStringContext";
import { usePathname } from "next/navigation";
import { Block } from "../../helpers/PageConfig";

const LEGAL_DISCLAIMER_PRODUCT_COOKIE_NAME =
  "inLegalDisclaimerProductDismissed";

const LEGAL_DISCLAIMER_GENERAL_COOKIE_NAME = "generalLegalDisclaimerDismissed";

export const LegalDisclaimersWrapper = ({ blocks }: { blocks: Block[] }) => {
  const localeString = useLocaleStringContext();
  const route = usePathname();

  /**
   * Using cookies here implies that this component is not suitable for
   * server-side rendering (SSR) because it will result in client-side hydration
   * mismatches. As such, this component should only ever be rendered in a
   * browser environment.
   */
  const [isLegalDisclaimerDismissed, setIsLegalDisclaimerDismissed] =
    useState<boolean>(
      () => Cookies.get(LEGAL_DISCLAIMER_GENERAL_COOKIE_NAME) === "true",
    );

  const [
    isLegalDisclaimerProductDismissed,
    setIsLegalDisclaimerProductDismissed,
  ] = useState<boolean>(
    () => Cookies.get(LEGAL_DISCLAIMER_PRODUCT_COOKIE_NAME) === "true",
  );

  const dismissLegalDisclaimer = () => {
    setIsLegalDisclaimerDismissed(true);
    Cookies.set(LEGAL_DISCLAIMER_GENERAL_COOKIE_NAME, "true");
  };

  const dismissLegalDisclaimerProduct = () => {
    setIsLegalDisclaimerProductDismissed(true);
    Cookies.set(LEGAL_DISCLAIMER_PRODUCT_COOKIE_NAME, "true");
  };

  switch (localeString) {
    case "fr-fr": {
      const possibleMessages = [
        "Pour votre santé, mangez au moins cinq fruits et légumes par&nbsp;jour.",
        "Pour votre santé, pratiquez une activité physique&nbsp;régulière.",
        "Pour votre santé, évitez de manger trop gras, trop sucré, trop&nbsp;salé.",
        "Pour votre santé, évitez de grignoter entre les&nbsp;repas.",
      ];
      return !isLegalDisclaimerDismissed ? (
        <LegalDisclaimer
          position="top"
          onClickDismissed={dismissLegalDisclaimer}
          message={
            possibleMessages[
              Math.floor(Math.random() * possibleMessages.length)
            ]
          }
          link={{
            text: "www.mangerbouger.fr",
            url: "https://www.mangerbouger.fr",
          }}
        />
      ) : null;
    }

    case "in-en":
      if (
        blocks.some(isBlocksProductHeroCan) &&
        (route?.includes("red-bull-sugarfree") ||
          route?.includes("red-bull-zero"))
      ) {
        return !isLegalDisclaimerProductDismissed ? (
          <LegalDisclaimerProduct
            onClickDismiss={dismissLegalDisclaimerProduct}
            productHeroCanBlock={blocks.find(isBlocksProductHeroCan)}
          />
        ) : null;
      }

      return null;

    case "cl-es":
      if (
        blocks.some(
          (block) =>
            isBlocksCompanyStage(block) ||
            isBlocksContactFormStage(block) ||
            isBlocksHighlightsStage(block) ||
            isBlocksIngredientsStage(block) ||
            isBlocksProductHeroCan(block) ||
            isBlocksProductRail(block) ||
            isBlocksProductStage(block) ||
            isBlocksQaArticle(block) ||
            isBlocksSustainabilityQuiz(block),
        ) &&
        !route?.includes("red-bull-sugarfree") &&
        !route?.includes("red-bull-zero")
      ) {
        return <LegalDisclaimerChile />;
      }

      return null;

    case "co-es":
      return !isLegalDisclaimerDismissed ? (
        <LegalDisclaimer
          position="bottom"
          onClickDismissed={dismissLegalDisclaimer}
          message="Contenido elevado en cafeína (32mg/100ml). La Bebida Energizante no previene los efectos generados por el consumo de bebidas alcohólicas. No se recomienda el consumo de bebidas energizantes con bebidas alcohólicas. Este producto solo podrá ser comercializado, expendido y dirigido a población mayor de 14 años. Este producto no es recomendado para personas sensibles a la cafeína."
        />
      ) : null;

    default:
      return null;
  }
};
